
import moment from 'moment';
import {mapDataToSubmission, addSignatureInSubmissionData} from '../services/data-mapping.service'
import SFAPI from './sfapi';
import { getNameSpaceField } from './namespace';
import IAccessManager from './IAccessManager';
import StubAccessManager from './StubAccessManager';
import { CQSubmission } from './CQSubmissionManager';
import { isIndexAvailable, storeSubmissionInDB, updateSubmissionDBItem } from './submissiondbapi';


export class CQAutoFillup{

    public static GENERAL = 'General';
    public static REFERENCE_FIELD_GROUP = 'Group';
    public static REFERENCE_FIELD_USER = 'User';

    sfAPI : SFAPI;
    accessManager: IAccessManager = new StubAccessManager();

    constructor(accessManager ?: IAccessManager){
        this.sfAPI = new SFAPI();
        if(accessManager !== undefined){
            this.setAccessManager(accessManager)
        }
    }

    /**
     * method to retrieve content form controlled document and update the data if main record id exists
     */
     public async updateContentAccordingFormSubmissionRecordId(formSubmissionRecord){
        try{
            let submission = await this.sfAPI.syncPendingSubmissions(formSubmissionRecord.Id);
            if(submission && submission.hasOwnProperty('status')){
                let isSubmissionAvailable = await isIndexAvailable(submission.id)
                submission = CQSubmission.syncSubmission(submission);
                if(isSubmissionAvailable){
                    await updateSubmissionDBItem(submission);
                }else{
                    await storeSubmissionInDB(submission);
                }
                return submission;
            }else if(submission && submission.hasOwnProperty('schema','ui')){
                return await this.processData(submission, formSubmissionRecord);
            }else{
                //handling this part for migration issue. Since in v1, we don't have any form JSON under related form submission record
                let formDef  =  await this.sfAPI.getFormDefinition(getNameSpaceField(formSubmissionRecord, 'SQX_Controlled_Document__c'));
                if(formDef){
                    return await this.processData(formDef, formSubmissionRecord);
                }
                return submission;
            }
        }catch(error){
            console.error(error);
        }
    }

    /**
     * @description process the submission data 
     * @param submission 
     * @param formSubmissionRecord 
     * @returns processed submission
     */
    private async processData(submission : any, formSubmissionRecord: any){
        let formDef = Object.assign({}, submission);
        let startingData : any = {};
        let keys : any = submission.schema.properties
        let objectKey : any = Object.keys(keys)
        if (objectKey.length > 1 || !formDef.hasOwnProperty('cqFormType', 'cqFormObjectSchema')){
            if(objectKey.indexOf(CQAutoFillup.GENERAL) > -1){ 
                let generalIndex : number = objectKey.indexOf(CQAutoFillup.GENERAL); 
                objectKey.splice(generalIndex, 1); 
            }
            let fieldKeys : any = Object.keys(keys[objectKey[0]].properties);
            let relationFields : any = fieldKeys.filter(element => element.endsWith('__r'));
            fieldKeys = fieldKeys.filter(element => !element.endsWith('__r'));
            let relationshipSchema = await mapDataToSubmission({ data : {}, formDef});
            if(fieldKeys.length > 0){
                // check if context object is selected and query it using relational field
                if(submission.hasOwnProperty('contextObject') && submission.hasOwnProperty('contextObjectRelationshipName')) {
                    if(submission.contextObject !== "") {
                        let contextFieldsToQuery : any = this.getContextFieldsToQuery(submission);
                        // check if there is any context fields to query and add it on field keys
                        if(contextFieldsToQuery.length > 0 ) {
                            fieldKeys.push(...contextFieldsToQuery);
                        }
                    }
                }
                let data : any = await this.sfAPI.findRelatedRecord(getNameSpaceField(formSubmissionRecord, 'Main_Record_Id__c'), objectKey[0], fieldKeys);
                if(data && data.records[0]) {
                    startingData[objectKey[0]] = data.records[0];
                    
                }
                if(submission.queries.length !== 0 && Object.keys(relationshipSchema['data']).length !== 0){
                    // added condition to check if multiple relationfields are available
                    if(Array.isArray(relationFields)) {
                        relationFields.map(relationField => {
                            // check if data has value for relationfields or not
                            let contextObjectRelationshipName : any = submission.contextObjectRelationshipName;
                            relationshipSchema['data'][objectKey[0]][contextObjectRelationshipName] = {};
                            // check whether the relationField is contextObjectRelationshipname or not
                            if(relationField === contextObjectRelationshipName){
                                relationshipSchema['data'][objectKey[0]][contextObjectRelationshipName] = startingData[objectKey[0]][relationField];
                            }
                            if(contextObjectRelationshipName !== relationField && relationshipSchema['data'][objectKey[0]][relationField]) {
                                startingData[objectKey[0]] = startingData[objectKey[0]] || {};
                                startingData[objectKey[0]][relationField] = relationshipSchema['data'][objectKey[0]][relationField];
                            }else if (relationshipSchema['data'][objectKey[0]][relationField] === null || relationshipSchema['data'][objectKey[0]][relationField] === undefined){
                                delete startingData[objectKey[0]][relationField];
                            }
                        })
                    } else {
                        startingData[objectKey[0]][relationFields] = relationshipSchema['data'][objectKey[0]][relationFields];
                    }
                }
                //signature will be added in data if present in formDef
                startingData = addSignatureInSubmissionData(submission, startingData);
                if(Object.keys(startingData).length > 0){
                    submission.data = await this.formatData(startingData, fieldKeys, objectKey[0], submission);
                }
            }
        }
        return submission;
    }


    /**
     * This method returns list of context fields to query
     * @param submission 
     * @returns list of context fields
     */
    public getContextFieldsToQuery(submission:any) {
        let contextFields : any = [];
        let contextObject : any = submission.contextObject;
        let contextObjectRelationshipName : any = submission.contextObjectRelationshipName;
        Object.keys(submission.schema.definitions[contextObject]['properties']).map(field => {
            contextFields.push(`${contextObjectRelationshipName}.${field}`);
        })
        return contextFields;
    }

    /**
     * This method format the time and lookup values according to JSON form
     * @param startingData : form starting values
     * @param fieldKeys : fields api name
     * @param objectKey : object api name
     * @param formDef : JSON data
     * @returns starting data
     */
    public async formatData(startingData, fieldKeys, objectKey, formDef){
        for(let i = 0 ; i < fieldKeys.length ; i++){
            if(startingData[objectKey][fieldKeys[i]] === undefined || startingData[objectKey][fieldKeys[i]] === null){
                delete startingData[objectKey][fieldKeys[i]];
                continue;
            }
            let value = startingData[objectKey][fieldKeys[i]].toString();
            const datePattern : RegExp = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(?:\.\d+)[+]([0-9])*/gm
            if(datePattern.test(value)){
                startingData[objectKey][fieldKeys[i]] = moment.utc(moment(startingData[objectKey][fieldKeys[i]])).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
            }
            let referenceField = formDef.schema.properties[objectKey].properties[fieldKeys[i]]?.lookupTo;        
            if(referenceField !== null && referenceField !== undefined){
                startingData[objectKey][fieldKeys[i]] = await this.processLookUpData(startingData, objectKey, fieldKeys[i], formDef, referenceField, false);
            }
        }

        if(startingData[objectKey].hasOwnProperty(formDef.contextObjectRelationshipName)){
            for(const item in startingData[objectKey][formDef.contextObjectRelationshipName]){
                if(startingData[objectKey][formDef.contextObjectRelationshipName][item] === undefined || startingData[objectKey][formDef.contextObjectRelationshipName][item] === null){
                    delete startingData[objectKey][formDef.contextObjectRelationshipName][item];
                    continue;
                }
                if(JSON.stringify(fieldKeys).includes(item)){
                    let referenceField = formDef.schema.definitions[formDef.contextObject]['properties'][item]?.lookupTo;
                    if(referenceField !== null && referenceField !== undefined) {
                         startingData[objectKey][formDef.contextObjectRelationshipName][item] = await this.processLookUpData(startingData, objectKey, item, formDef, referenceField, true);
                    }
                }
            }
        }

        return startingData;
    }

    /**
     * This method process lookup schema
     * @param startingData 
     * @param objectKey 
     * @param fieldKey 
     * @param referenceField 
     * @returns 
     */
    public processLookUpData = async (startingData, objectKey, fieldKey, formDef, referenceField, isContextObject) => {
        let data = await this.sfAPI.findRelatedRecord( isContextObject ? startingData[objectKey][formDef.contextObjectRelationshipName][fieldKey] : startingData[objectKey][fieldKey], referenceField, 'Name');         
        if(referenceField === CQAutoFillup.REFERENCE_FIELD_GROUP && !data.records.length){
            data = await this.sfAPI.findRelatedRecord(isContextObject ? startingData[objectKey][formDef.contextObjectRelationshipName][fieldKey] : startingData[objectKey][fieldKey], CQAutoFillup.REFERENCE_FIELD_USER, 'Name');
            return data.records.length ? data.records[0].Name : '';
        }else{
            return data.records.length ? data.records[0].Name : '';
        }
    }

    public setAccessManager(accessManager : IAccessManager) : CQAutoFillup {
        this.accessManager = accessManager;
        this.sfAPI.setAccessManager(accessManager);

        return this;
    }

}