import React, { useEffect, useState, useContext, useRef, CSSProperties  } from 'react';
import { Button, Link } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import strings from '../localizations/formScreen'
import '../assets/slds/styles/salesforce-lightning-design-system.css'

import Alert from '@material-ui/lab/Alert';
import { JsonForms } from '@jsonforms/react';
import { useHistory } from "react-router-dom";
import { createAjv, setReadonly } from '@jsonforms/core';
import { IOAuthRequestOptions } from '../interfaces/IApp.interface';
import { CQAPIService } from '../services/http-api.service';

import Backdrop from '@material-ui/core/Backdrop';
import { Snackbar } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@salesforce/design-system-react/components/modal';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import {
    materialRenderers,
    materialCells
} from '@jsonforms/material-renderers';
import Spinner from '@salesforce/design-system-react/components/spinner';
import moment from 'moment';

import CQLookup from '../renderer/CQLookup';
import CQLookupTester from '../renderer/CQLookupTester';
import CQFileUpload from '../renderer/CQFileUpload';
import CQFileUploadTester from '../renderer/CQFileUploadTester';
import CQSubForm from '../renderer/CQSubForm';
import CQSubFormTester from '../renderer/CQSubFormTester';
import { renderRoutes } from 'react-router-config';
import CQMaterialListItem from '../renderer/CQMaterialListItem';
import CQMaterialListItemTester from '../renderer/CQMaterialListItemTester';
import CQFileCard from '../renderer/CQFileCard';
import CQFileCardTester from '../renderer/CQFileCardTester';
import CQSignatures from '../renderer/CQSignatures';
import CQSignaturesTester from '../renderer/CQSignaturesTester';
import CQSignature from '../renderer/CQSignature';
import CQSignatureTester from '../renderer/CQSignatureTester';
import CQLabel from '../renderer/CQLabel';
import CQLabelTester from '../renderer/CQLabelTester';
import CQGrid from '../renderer/CQGrid';
import { CQGridTester } from '../renderer/CQGridTester';
import CQSection from '../renderer/CQSection';
import { CQSectionTester } from '../renderer/CQSectionTester';
import CQSelect from '../renderer/CQSelect';
import CQSelectTester from '../renderer/CQSelectTester';
import CQQuickLinks from '../renderer/CQQuickLinks';
import CQQuickLinksTester from '../renderer/CQQuickLinksTester';
import CQFlexLayout from '../renderer/CQFlexLayout';
import CQFlexLayoutTester from '../renderer/CQFlexLayoutTester';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CQHeader from './CQHeader';
import { useTheme } from '@material-ui/core/styles';
import CQFileUploaderContext  from '../context/CQFileUploaderContext';
import CQSubmissionContext  from '../context/CQSubmissionContext';
import AccessManagerContext from '../context/AccessManagerContext';
import {addFormBuilderDataInSubmissionData, mapDataToSubmission } from '../services/data-mapping.service';
import { CQApiConstant } from '../api/api-constants';
import CQDate from '../renderer/CQDate';
import CQDateTester from '../renderer/CQDateTester';
import CQInput from '../renderer/CQInput';
import CQInputTester from '../renderer/CQInputTester';
import CQCheckbox from '../renderer/CQCheckbox';
import CQCheckboxTester from '../renderer/CQCheckboxTester';
import CQTabs from '../renderer/CQTabs';
import CQTabsTester from '../renderer/CQTabsTester';
import CQTab from '../renderer/CQTab';
import CQTabTester from '../renderer/CQTabTester';
import CQTextAreaTester from '../renderer/CQTextAreaTester';
import CQTextArea from '../renderer/CQTextArea';
import CQDateTimeTester from '../renderer/CQDateTimeTester';
import CQDateTime from '../renderer/CQDateTime';
import CQGuide from '../components/CQGuide';
import CQGuideTester from '../renderer/CQGuideTester';
import CQBlank from '../renderer/CQBlank';
import CQBlankTester from '../renderer/CQBlankTester';
import CQDependentPicklist from '../renderer/CQDependentPicklist';
import CQDependentPicklistTester from '../renderer/CQDependentPicklistTester';
import SFAPI from '../api/sfapi';
import CQGenericFileUploadTester from 'renderer/CQGenericFileUploadTester';
import CQGenericFileUpload from 'renderer/CQGenericFileUpload';
import CQNumber from 'renderer/CQNumberControl';
import CQNumberTester from 'renderer/CQNumberControlTester';
import { CQDiscardModal } from './CQDiscardModal';
import CQTableTester from 'renderer/CQTableTester';
import CQTable from 'renderer/CQTable';
import { isMobileOnly, isIOS } from 'react-device-detect';

import { useLocation } from 'react-router-dom';

import CQRadioTester from 'renderer/CQRadioTester';
import CQRadio from 'renderer/CQRadio';
import LCC from 'lightning-container';
import CQTimeTester from 'renderer/CQTimeTester';
import CQTime from 'renderer/CQTime';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        sigCanvas: {
            width: '100%',
            border: 'solid 1px black'
        }
    }),
);

function addDisableIfReadonly(uischema: any, level: number) {
    if (uischema.elements) {
        uischema.elements.forEach((element: any) => {
            addDisableIfReadonly(element, level + 1);
        })
    } else if (uischema.type === 'Control') {
        uischema.rule = {
            "effect": "ENABLE",
            "condition": {
                "scope": '#',
                "schema": { 'const': true }
            }
        }
    } else if (uischema.layout) {
        addDisableIfReadonly(uischema.layout, level + 1);
    }
} 

function addCQValidationToEachField(schema: any, level: number) {
    console.log('In level ' + level);
    if (level > 5) {
        return;
    }
    if (schema.properties) {

        for (let key in schema.properties) {
            if (schema.properties[key].type) {
                if (schema.properties[key].type === 'object') {
                    addCQValidationToEachField(schema.properties[key], level + 1);
                } else {
                    schema.properties[key].cqcustomvalidation = true;
                }
            }
        }
    }
}

const renderers = [
    ...materialRenderers,
    //register custom renderers
    {tester: CQTimeTester, renderer: CQTime},
    { tester: CQGridTester, renderer: CQGrid },
    { tester: CQSectionTester, renderer: CQSection},
    { tester: CQLookupTester, renderer: CQLookup },
    { tester: CQGuideTester,  renderer: CQGuide},
    { tester: CQBlankTester, renderer: CQBlank},
    { tester: CQFileUploadTester, renderer: CQFileUpload },
    {tester: CQGenericFileUploadTester, renderer: CQGenericFileUpload},
    { tester: CQSubFormTester, renderer: CQSubForm },
    { tester: CQMaterialListItemTester, renderer: CQMaterialListItem },
    { tester: CQFileCardTester, renderer: CQFileCard },
    { tester: CQSignatureTester, renderer: CQSignature },
    { tester: CQSignaturesTester, renderer: CQSignatures },
    { tester: CQLabelTester, renderer: CQLabel },
    { tester: CQSelectTester, renderer: CQSelect },
    { tester: CQQuickLinksTester, renderer: CQQuickLinks},
    { tester: CQFlexLayoutTester, renderer: CQFlexLayout},
    { tester: CQDateTester, renderer: CQDate},
    { tester: CQInputTester, renderer: CQInput},
    { tester: CQTabTester, renderer: CQTab},
    { tester: CQTabsTester, renderer: CQTabs},
    { tester: CQCheckboxTester, renderer: CQCheckbox},
    { tester: CQTextAreaTester,renderer: CQTextArea},
    { tester: CQDateTimeTester,renderer:CQDateTime},
    { tester: CQDependentPicklistTester,renderer:CQDependentPicklist},
    {tester: CQTableTester, renderer: CQTable},
    {tester: CQRadioTester, renderer: CQRadio},
    {tester: CQNumberTester, renderer: CQNumber}
];


function CQForm({ route = {}, dispatch, match = {params: ''}, selectedFormDefinition }: any) {

    const fileUploader = useContext(CQFileUploaderContext);
    const submissionManager = useContext(CQSubmissionContext);
    const accessManager = useContext(AccessManagerContext);

    let cqAPIService = new CQAPIService();
    const sfAPI = new SFAPI().setAccessManager(accessManager);
    
    const classes = useStyles();
    const ajv = createAjv({
        allErrors: true,
        jsonPointers: true
    });
    ajv.addKeyword("cqcustomvalidation", {
        type: "string",
        validate: function cqcuval(
            schema: any,
            data: any,
            parentSchema?: object,
            dataPath?: string,
            parentData?: object | Array<any>,
            parentDataProperty?: string | number,
            rootData?: object | Array<any>
        ): boolean {
            let isValid = true;
            console.log(dataPath);

            if (submission?.errors?.response?.data?.length > 0) {
                let errors = submission?.errors?.response?.data;
                let relevantErrors: any = [];
                if (errors.forEach) {
                    errors.forEach((err: any) => {
                        if (err.errorPath === dataPath) {
                            relevantErrors.push({
                                keyword: "cqcustomvalidation",
                                message: err.errorMessage
                            });
                        }
                    });

                    if (relevantErrors.length > 0) {
                        let t: any = cqcuval;
                        t.errors = relevantErrors;
                        isValid = false;
                    }
                }

            }
            return isValid;
        },
        errors: true
    });



    const formChanges = {
        data: {},
        errs: []
    }

    const [submission, setSubmission]: any = useState([]);
    const [hasError, setHasError] = useState(false);
    const [errors, setErrors] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isReadonly, setIsReadOnly] = useState(false);
    const [isSFSubmission, setIsSFSubmission] = useState(false);
    const [showSubmitPrompt, setShowSubmitPrompt] = useState(false);
    const [isOpenDiscardModal, setIsOpenDiscardModal] = useState(false);
    
    const formChangesDataRef = useRef({});
    
    const history = useHistory();

    let isDirty = false;

    const setSubmissionId = (id) => {
        console.log('setsubmissionId Called');
        fileUploader.submissionId = id;
        submissionManager.submissionId = id;
    }

    const clearSubmissionId = () => {
        fileUploader.submissionId = '';
        submissionManager.submissionId = '';
    }

    const formFooterClassNames : string = "slds-grid slds-gutters slds-grid_align-center slds-container_medium slds-m-top_large cq-bottom-container";

    //method to set styles for the footer buttons in form
    const getFooterStyles = () => {
        let styleObj : CSSProperties  = {
            position: "fixed",
            left:'0', 
            backgroundColor: '#f3f2f2',
            width: '100%',
            padding: '.5rem 0',
            zIndex: 99999,
        }
        return styleObj;
    }

    const location = useLocation();

    useEffect(() => {

        // async method inside useEffect for await code
        const asyncSubmissionSetup = async(id) => {
            let submission : any;
            const params = new URLSearchParams(location.search);
            const fileName = params.get('fileName');
            if(match.params.formid){
                submission = await submissionManager.getPendingSubmission(match.params.formid);
            }
            else if(fileName){
                submission = await submissionManager.getSubmissionDataByFileName(fileName);
                setIsReadOnly(true);
            }

            if (!submission) {
                setHasError(true);
            }else if ( typeof submission.formDef === 'string' ) {
                setHasError(true);
                setErrors( strings.Error + JSON.stringify(submission.formDef));            
            }else {
                await mapDataToSubmission(submission);
                if(!submission.data.hasOwnProperty("General")){
                    await addFormBuilderDataInSubmissionData(submission);
                }
                //Assign originalData to the submission record, once form is submitted then originalData is removed from the submission record.
                if(!submission.hasOwnProperty('originalData')){
                    submission.originalData = Object.assign({}, submission.data);
                }else if(submission.originalData && !Object.keys(submission.originalData).length){       //Assign submission data to originalData when originalData is empty object
                    submission.originalData = Object.assign({}, submission.data);
                } else if(submission.data.hasOwnProperty('General') && !(submission.originalData.hasOwnProperty('General'))) {
                    submission.originalData = Object.assign({}, submission.data);
                }
                let schema = submission.formDef.schema;
                let ui = submission.formDef.ui;

                schema.definitions = schema.definitions || {};
                schema.definitions.File = {
                    "type": "object",
                    "title": "File",
                    "properties": {
                        "ContentDocumentId": {
                            "type": "string",
                            "title": "ContentId"
                        },
                        "LocalId": {
                            "type": "string",
                            "title": "Local Id"
                        },
                        "Name": {
                            "type": "string",
                            "title": "File Name"
                        }
                    }
                };

                schema.definitions.Signature = {
                    "type": "object",
                    "preventDeletion": true,
                    "preventAddition": true,
                    "title": "Signature",
                    "properties": {
                        "SignatureBase64": {
                            "type": "string"
                        }
                    }
                }

                addCQValidationToEachField(schema, 1);
                if (schema.definitions) {
                    for (let key in schema.definitions) {
                        addCQValidationToEachField(schema.definitions[key], 1);
                    }
                }

                if (isReadonly) {
                    addDisableIfReadonly(ui, 1);
                }

                
                setSubmission(submission);
                if(submission && submission.id){
                    setSubmissionId(submission.id);
                    submissionManager.submission = submission;
                }
            }
        }

        

        if(selectedFormDefinition) {
            submissionSetupFromSelectedFormDefinition();
        } else {
            let isReadonly = match.params.formid === 'selected-submission'
            setIsReadOnly(isReadonly);
            
            //check if launched submission
            let isSFSubmission = accessManager.isCanvasMode === true;
            setIsSFSubmission(isSFSubmission);

            asyncSubmissionSetup(match.params.formid);
            
        }
    }, []);

    /**
     * This method used to set submission if selectedFormDefinition exists.
     */
    const submissionSetupFromSelectedFormDefinition = async () =>{
        await mapDataToSubmission(selectedFormDefinition);
        if(submission.originalData && !Object.keys(submission.originalData).length){
            submission.originalData = Object.assign({}, submission.data); 
        }
        setSubmission(selectedFormDefinition);
        if(submission && submission.id){
            setSubmissionId(submission.id);
        }
        setIsReadOnly(true);
    }

    const setSubmissionNetworkError = () => {
        submission.errors = [{"message":strings.networkError}];
    }

    const changedFormData = async ({ errors, data }: any) => {
        formChanges.data = data;
        formChanges.errs = errors;

        if(Object.keys(formChanges.data).length !== 0 && submissionManager.submission){
            isDirty = true;
            if(isSubmitting){
                submission.data = formChangesDataRef.current;
            }else{
                formChangesDataRef.current = formChanges.data;
            }
            // updates the form data in submission record that stores in IndexedDB.
            await submissionManager.updateSubmissionInDB(submission, formChangesDataRef.current);
        }
    }


    useEffect(() => {
        if(submission.errors && Object.keys(submission.errors).length > 0){
            scrollToTop();
        }
        if(submission?.data && submission?.data !== formChanges.data ){
            formChanges.data = submission.data;
        }
         const saveForm = async () => {
            await submissionManager.updateSubmission(match.params.formid, formChangesDataRef.current);
        }

        const eventListener = async (event) =>  {
            if (!isDirty) {
                return undefined;
            }else{
                var confirmationMessage = 'It looks like you have been editing something. '
                                        + 'If you leave before saving, your changes will be lost.';
                saveForm();
                event.preventDefault();
                event.returnValue = confirmationMessage;
                return confirmationMessage;
            }
        }

        window.addEventListener('beforeunload', eventListener);
        return () => {
            window.removeEventListener('beforeunload', eventListener);
        };
    }, [formChanges.data, isDirty, match.params.formid, submissionManager]);

    const [toastErrorMsg, setToastErrorMsg] = useState('');
    /**
     * Initates salesforce login
     * @param oAuthRequestBody 
     */
    async function initiateJsForceOauthConnection(oAuthRequestBody: IOAuthRequestOptions) {
        try {
            await cqAPIService.login(oAuthRequestBody).then(async (connection) => {
                await sfAPI.setUserDetails(connection).then(() => {
                    formSubmitted();
                })
            });
        } catch (error) {
            console.error(error);
        }
    }

    /**
     * This method starts login process
     * fetch configuration 
     */
    const reloginUser = async () => {
        let request: IOAuthRequestOptions = {};
        try{
            await fetch('/api/getEnv/')
                .then(response => response.json()
                    .then(data => {
                        request = { ...data};
                    }));     
        }catch(e){
            submission.errors = JSON.stringify(e);
        }
        request.SF_OAUTH_LOGIN_URL = submission.instanceUrl;
        await initiateJsForceOauthConnection(request);
    }

    /**
     * This method perform token validation 
     * Depending on token validation response further operation are carried
     */
    const checkAccessTokenStatus = async () => {
        setIsSubmitting(true);
        try{
            let response = await sfAPI.accessTokenStatus();
            if(!window.navigator.onLine){ throw Error(strings.networkError)};
            if(!response){
                throw Error(strings.authenticationFailed);
            }
            else if(response.active === false) {
                await reloginUser()
            }else if (response.active === true){
                formSubmitted();
            }
        }catch(error){
            if(Object.keys(formChanges.data).length !== 0){
                submission.data = formChanges.data;
            }

            // submit form on offline
            formSubmitted();

            // update the submission error if no validation error is available
            if (!(ajv.validate(submission.formDef.schema, formChanges.data) === false)) {
                submission.errors =  [{message: strings.networkError}];
            }

            submission.errors.map(async(error) =>  {
                if (error.message === strings.networkError) {
                    setSubmissionNetworkError();
                    await submissionManager.submitSubmission(match.params.formid, formChanges.data);
                    if(!isSFSubmission){
                       submissionManager.formSubmitted = true;
                       history.push('/');
                       setTimeout(() =>{
                        submissionManager.formSubmitted = false;
                    }, CQApiConstant.TOAST_TIMER);
                    }
                }
            })
            setIsSubmitting(false);
        }
    }

    const handleErrors = (errors: any) => {
        // array of submission errors
        let submissionErrors: any = [];
        let errorMessage: any;
        let fieldTitle: any;

        errors.map((error: any) => {
            let isParentField : boolean = false;
            if (error.keyword === 'required' || error.keyword === 'invalid') { //check if error is for required property or not
                //TODO: keep this block in a separate method. we might want to update it later with a more ajv compliant method.
                const schemaFields = error.dataPath.split("/"); // store field in an array from scope
                let fieldApi = schemaFields.pop(); // store last field in the field array e.g "cqext__start_date"
                if (error.schema[fieldApi]?.hasOwnProperty("title") && !(/\d/.test(error.dataPath))) {
                    fieldTitle = error.schema[fieldApi]["title"]; // store title from field api name e.g start date
                } else {
                    if (!(error.schema[fieldApi]?.hasOwnProperty('properties'))) { // check if incoming field is an object or not
                        isParentField = true;
                    }
                    if(!isParentField){
                        let fieldTitleForChild = fieldApi.includes('SQX_') ? fieldApi.match(/SQX_(.*?)__c/)[1] : fieldApi.match(/__(.*?)/) ? fieldApi.match(/__(.*?)__c/)[1] : fieldApi; // store field name from fieldapi for chid object e.g 'Finding', Objective_Evidence'
                        if (fieldTitleForChild.includes('_')) { // check if field name has '_'
                            fieldTitle = fieldTitleForChild.split('_').join(" "); // remove '_' from fieldname e.g 'objective evidence'
                        } else {
                            fieldTitle = fieldTitleForChild;
                        }
                    }else{
                        error.schema.hasOwnProperty(fieldApi) ? fieldTitle = error.schema[fieldApi]["title"] : fieldTitle = error.schema["title"];
                    }  
                }
                if(/\d/.test(error.dataPath) && !isParentField) { // check if datapath has number to find out the index number of child objects data
                    let numberinPath = parseInt(error.dataPath.match(/\d+/)[0]); // store index of child objects data
                    errorMessage = `${fieldTitle} ${numberinPath + 1} ${error.message ? error.message : strings.requiredfieldsMissing}`; // store error message for child object's field
                } else {
                    errorMessage = `${fieldTitle} ${error.message}`; // store error message for main object field
                }
                submissionErrors = [...submissionErrors, { message: errorMessage }]; // store error messages
            }
        })
        submission.errors = submissionErrors; // assign all errors to submission errors
        return submission.errors;
    }

    /**
     * This method skips validation for table when data for the field is empty.
     * @param data
     * @returns 
     */
    const skipBlankFieldRegexValidation = async (errors: any) => {
        let hasPatternError = false;
        errors?.some((error: any) => {
            if (error.keyword === 'pattern' && error.data === undefined )  {
                hasPatternError = false;
                return false;
            } else {
                hasPatternError = true;
                return true;
            }
        });
        return hasPatternError;
    }

    /**
     * This method is used to perform form submission
     * @returns 
     */
    const performSubmission = async () => {
        try {
            if(Object.keys(formChanges.data).length !== 0){
                submission.data = formChanges.data;
            }
            let unresolvedFiles = submissionManager.getUnresolvedFiles(submission.data);
            if(unresolvedFiles && unresolvedFiles.length > 0 ){
                if(isSFSubmission){
                    let errorMsg ='';
                    if(unresolvedFiles[0] && unresolvedFiles[0].error){
                        errorMsg = strings.fileUploadError;
                    }else if(window.navigator.onLine){
                        errorMsg = strings.fileUploading;
                    }
                    submission.errors = [{"message":errorMsg}];
                    return;
                }

                if(unresolvedFiles[0] && unresolvedFiles[0].error){
                    dispatchSubmission(submission.data, false);
                }else if(window.navigator.onLine){
                    setToastErrorMsg(strings.uploadingFile);
                    setShowSubmitPrompt(true);
                }
            } else{
                setIsSubmitting(true);

                const formSubmissionIdentifier = submission.formId + '#' + submission.id;
                if(!window.navigator.onLine){ throw Error(strings.networkError)};
                await submissionManager.saveSubmissionJsonForPdfRendering(submission, encodeURIComponent(formSubmissionIdentifier));
                let data: any = await submissionManager.submitToSF(submission);

                setSubmission(submission);
                if(data && data.url){
                    if(isSFSubmission){
                        history.push(`/launchFromSF/submission-finished?dataUrl=${data.url}`);
                    }else{
                        submissionManager.formSubmitted = true;
                        handleClickOpen();
                        clearSubmissionId();
                        setTimeout(() =>{
                            submissionManager.formSubmitted = false;
                        }, CQApiConstant.TOAST_TIMER);
                        
                        history.push('/');
                    }
                }else{
                    if(Array.isArray(data) && data.length){
                        let errors : any = [];
                        data.forEach(error => {
                            errors.push(error.errorMessage);
                        });
                        submission.errors = errors;
                    }else{
                        submission.errors =  [{message: data.errorMessage || data }];
                    }
                }
            }
        } catch (e) {
            
            if (e.message === 'Network Error') {
                if(isSFSubmission){
                    setSubmissionNetworkError();
                }
                dispatchSubmission(submission.data, false);
            }else if(e.message){
                submission.errors = [{message: e.message}];
            } else {
                submission.errors = { response: e.response || e };
                setSubmission(JSON.parse(JSON.stringify(submission)));
            }
        }finally{
            setIsSubmitting(false);
        }
    }
    const formSubmitted = async () => {
        //clear last server submission errors
        setShowSubmitPrompt(false);
        submission.errors = {};
        //check whether the data follows the same pattern description on schema
        const validateSubmission = ajv.validate(submission.formDef.schema, formChanges.data);
        const hasPatternError = await skipBlankFieldRegexValidation(ajv.errors);    
        if(hasPatternError){
            if (validateSubmission === false) { 
                alert("Please fix the field validation before submitting");
                handleErrors(ajv.errors);
                setIsSubmitting(false);
                return;
            }else if(submission.error.length){
                handleErrors(submission.error);
                alert('Please fix the errors before submitting');
                setIsSubmitting(false);
                return;
            }
        }
        await performSubmission();
    }
    /**
     * This method is used to scroll to top whenever there is error
     */
    const scrollToTop = () => {
        //check whether the its mobile or desktop
        const formContainer = document.querySelector('.cq-form-container');
        if (formContainer) {
          formContainer.scrollTo({ behavior: 'smooth', top:0 });
        } else {
          window.scrollTo({
            top: 0,
            behavior: "smooth"
          });
        }
      }

    const formClosed = () => {
        let formData = {};
        if(Object.keys(formChanges.data).length !== 0){
            formData = formChanges.data;
        }else{
            formData = submission.data;
        }
        dispatchSubmission(formData, true);
    }
    
    const formClosedReturnSF = () => {
        if(isReadonly) {
            LCC.sendMessage({
                instructions: "closeReturnSF"
            });
            return;
        }
        let recId = localStorage.getItem('recordIdValue');
        if(localStorage.getItem('instanceurl')){
            if(localStorage.getItem('targetOrigin')) {
                history.push(`/launchFromSF/submission-closed?dataUrl=${localStorage.getItem('targetOrigin')}/${recId}`);
            } else {
                window.open(localStorage.getItem('instanceurl')+'/'+recId,"_top"); 
            }
        }
    }

    const getError = (): any => {
        return submission?.errors?.response?.data || submission?.errors || [];
    }

    const getSubErrorsAt = () => {
        let topLevelErrors: any = getError();


        return topLevelErrors && topLevelErrors.map ?
            <ul>
                {
                    topLevelErrors.map((err: any, index: any) => (<li key={index}> { index+1 + '. ' +  getMessage(err)}</li>))
                }
            </ul> : ''
    }

    const getMessage = (err) => {
        if(err.hasOwnProperty('message')){
            return err.message;
        }
        return err;
    }


    const hasErrors = () => {
        return (submission.errors && Object.keys(submission.errors).length > 0);
    }

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        history.push('/');
    };

    const dispatchSubmission= async (formData, isUpdate: Boolean) => {
        try{
            if(!isUpdate){
                submission.version++;
                submission.status = CQApiConstant.SUBMISSION.STATUS_SUBMITTED;
                await submissionManager.submitSubmission(match.params.formid, formData);
            }else{
                await submissionManager.updateSubmission(match.params.formid, formData);
            }
            clearSubmissionId();
            history.push('/');
        }catch(ex){
            submission.errors = { response: ex.response || ex };
            setSubmission(JSON.parse(JSON.stringify(submission)));
        }
    }
    
    const toggleOpen = () => {
        setShowSubmitPrompt(!showSubmitPrompt);
    }

    /**
     * This method opens modal when clicked on discard button
     */
    const openDiscardModal = () => {
        setIsOpenDiscardModal(true);
    }

    /**
     * This method closes modal when clicked on discard button
     */
    const closeDiscardModal = () => {
        setIsOpenDiscardModal(false);
    }

    /**
     * This method deletes launched submissions with main record id on clicking discard
     */
    const onDiscardModalDiscard = () => {
        submissionManager.deleteSubmissionFromDB(submission.id);
        history.push("/");
    }

    const getListComponents = () => {
        return (submission.formDef ? (
            <>
            <div className={isMobileOnly ? 'cq-form-app' : 'cq-form-desktop'}>
                <div className='cq-form-header'>
                    <CQHeader logo={submission?.formDef?.styles?.header?.logo} header={submission?.formName} subheader={moment(submission?.createdOn).calendar()}/>
                    <IconSettings iconPath="/assets/icons">
                        <Modal
                            dismissOnClickOutside={false}
                            isOpen = {showSubmitPrompt}
                            onRequestClose = {toggleOpen}
                        > 
                            <section className="slds-p-around_medium">
                            <div className="slds-grid slds-gutters slds-grid_align-center">
                                {toastErrorMsg}
                            </div>
                            </section>
                        </Modal>
                    </IconSettings>
                </div>
                <div className={isMobileOnly ? 'cq-form-container' : ''}>
                    <Container maxWidth={false} className="cq-form" >
                        {isSubmitting ?
                            <Backdrop className={classes.backdrop} open={isSubmitting}>
                                <CircularProgress color="inherit" />
                            </Backdrop> : ''}
                        {hasErrors() ?
                        <Alert severity="error">{strings.identifiedError}{getSubErrorsAt()}</Alert> : ''}
                        <IconSettings iconPath="/assets/icons">
                            <div className={isMobileOnly && isSFSubmission && isIOS ? 'cq-form-content' : ''}>
                                <JsonForms
                                    schema={submission.formDef.schema}
                                    uischema={submission.formDef.ui}
                                    renderers={renderers}
                                    data={submission.data}
                                    cells={materialCells}
                                    onChange={changedFormData}
                                    config={ {
                                        "cqconfig": {
                                            "lookupCache": submission.formDef.lookupCacheData,
                                            "queries": submission?.formDef?.queries,
                                            "startingdata": submission?.formDef?.startingData
                                        }
                                    }}
                                    ajv={ajv}
                                    validationMode='ValidateAndHide'
                                />
                            </div>
                        </IconSettings>
                        <CQDiscardModal 
                            openModal={isOpenDiscardModal} 
                            closeModal={closeDiscardModal} 
                            handleDiscardForDiscardModal={onDiscardModalDiscard}
                        />    

                        <Dialog
                            fullScreen={fullScreen}
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle id="responsive-dialog-title">{"Form submitted/queued for submission"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                {strings.successfulSubmission}
                        </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button autoFocus onClick={handleClose} color="primary">
                                    {strings.close}
                            </Button>
                            </DialogActions>
                        </Dialog>
                    </Container>
                </div>
                {!isReadonly ?
                    <div className='cq-form-footer'>
                        <div className={isMobileOnly ? (!isSFSubmission ?  formFooterClassNames +' slds-docked-form-footer' : isIOS ? formFooterClassNames+' cq-form-footer':  formFooterClassNames +' slds-docked-form-footer') : formFooterClassNames} style={isMobileOnly && isSFSubmission? 
                            getFooterStyles()
                        : {}}>
                            <div className="slds-item"><button className="slds-button slds-button_brand" onClick={checkAccessTokenStatus}>{strings.save}</button></div>
                            {!isSFSubmission?
                                <div className="slds-item"><button className="slds-button slds-button_neutral slds-m-left_large" onClick={formClosed}>{strings.close}</button></div>
                                :<div className="slds-item"><button className="slds-button slds-button_neutral slds-m-left_large" onClick={formClosedReturnSF}>{strings.close}</button></div>}
                            {submission.hasOwnProperty('isSalesforceRecord') && !submission.isSalesforceRecord && !isSFSubmission ?<div className="slds-item slds-m-left_large"><button className="slds-button slds-button_neutral" onClick={openDiscardModal}>{strings.discard}</button></div>:""}
                        </div> 
                    </div>
                    : 
                    <div className='cq-form-footer'>
                        <div className={isMobileOnly ? (!isSFSubmission ?  formFooterClassNames +' slds-docked-form-footer' : isIOS ? formFooterClassNames+' cq-form-footer':  formFooterClassNames +' slds-docked-form-footer') : formFooterClassNames} style={isMobileOnly && isSFSubmission? 
                            getFooterStyles()
                        : {}}>
                            <div className="slds-item">
                                <button className="slds-button slds-button_neutral slds-m-left_large" onClick={formClosedReturnSF}>{strings.close}</button>
                            </div>
                        </div>
                    </div>
                }
            </div>
            </>
        ) : <Spinner
                size="medium"
                variant="base"
                assistiveText={{ label: strings.loading }}
            /> 
        );
    }

    // updated back button to work as form close to save data
    window.addEventListener('popstate', function(event) {
        formClosed();
    })

    return (
        <>

            {
                hasError === true ? 
                <>         
                    <Snackbar
                        autoHideDuration={6000}
                        open
                        anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                        }}
                        message={errors}/>
                    <div>{strings.misMatchSubmission} 
                        <Link href="/">{strings.goBack}</Link></div> 
                </> : (
                    getListComponents()
                )}

            {renderRoutes(route.routes)}
        </>
    );
}



export default CQForm;